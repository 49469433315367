.my-divide__wrap {
  width: 100%;
  display: flex;
  min-height: 0.875rem;
  align-items: center;
  cursor: default;
}

.my-divide__line {
  width: 100%;
  height: var(--borderSize);
}

.my-divide__line_small {
  width: 1rem;
  height: var(--borderSize);
  background-color: var(--gray);
}

.my-divide__text {
  width: max-content;
  padding: 0 var(--paddingBase);
  font-size: var(--fontSizeExtraSmall);
  white-space: nowrap;
  line-height: normal;
  color: var(--gray);
}

.my-divide__hovered {
  color: var(--primary);
}
