.my-select__wrap,
.my-select__inside-wrap {
  position: relative;
  width: 100%;
}

.my-select__icon {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: var(--iconSize);
  height: var(--iconSize);
}

.my-select__dropdown {
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  max-height: 15rem;
  overflow-y: auto;
  border: var(--border);
  border-radius: var(--borderRadius);
  background-color: var(--white);
  z-index: 1000;
}

.my-select__option {
  padding: var(--paddingSmall) var(--paddingBase);
  color: var(--black);
  cursor: pointer;
}

.my-select__option:hover {
  background-color: var(--primary);
  color: var(--white);
}
