:root {
  --black: #000;
  --white: #fff;
  --green: #52c41a;
  --primary: #1677ff;
  --gray: #ccc;
  --red: #ff0000;

  --fontSizeExtraSmall: 0.75rem; /* 12px */
  --fontSizeSmall: 0.875rem; /* 14px */
  --fontSizeBase: 1rem; /* 16px */
  --fontSizeBig: 1.125rem; /* 18px */

  --paddingExtraSmall: 0.25rem; /* 4px */
  --paddingSmall: 0.375rem; /* 6px */
  --paddingBase: 0.5rem; /* 8px */

  --borderSize: 0.0625rem; /* 1px */
  --borderSizeBig: 0.5rem; /* 8px */
  --borderRadius: 0.25rem; /* 4px */
  --border: var(--borderSize) solid var(--gray);
  --borderBig: var(--borderSizeBig) solid var(--gray);

  --gap-extraSmall: 0.0625rem; /* 1px */
  --gap-small: 0.25rem; /* 4px */
  --gap: 0.5rem; /* 8px */

  --transparentBlack: rgba(0, 0, 0, 0.5);

  --boxShadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  --iconSize: 1rem; /* 16px */
  --iconSizeBig: 10rem; /* 160px */

  --uploadFileSingleMobileWidth: 100%;
  --uploadFileSingleMobileHeight: 21.75rem; /* 348px */
  --uploadFileSingleDesktopWidth: 9.25rem; /* 148px */
  --uploadFileSingleDesktopHeight: 9.25rem; /* 148px */
}

body::-webkit-scrollbar {
  width: 0;
}

#root {
  min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
  text-decoration: none;
  font-size: var(--fontSizeBase);
  box-sizing: border-box;
}

a {
  color: var(--black);
}

.container {
  width: 95vw;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin: 0 auto;
  overflow: hidden;
}

.ant-modal-footer,
.ant-modal-close {
  display: none;
}

.ant-typography {
  margin: 0 !important;
}

div.ant-upload.ant-upload-select,
span.ant-upload-wrapper,
div.ant-upload-list-item-container {
  width: 100%;
}

.ant-image-img {
  border-radius: var(--borderRadius);
}

div.ant-upload-list.ant-upload-list-picture-card {
  display: flex;
  gap: var(--gap);
}

div.ant-upload-list.ant-upload-list-picture-card::before {
  display: none;
}

@media (min-width: 992px) {
  .upload-image {
    max-width: 9.25rem;
  }
}

:where(.css-dev-only-do-not-override-nllxry).ant-empty.ant-empty-image {
  height: 126px;
  width: 100%;
  margin-bottom: 0;
}

.my-card {
  transition: box-shadow 0.3s ease;
}

.my-card:hover {
  box-shadow: var(--boxShadow);
}

.my-input__item::placeholder {
  color: var(--gray);
}
